import { SysTeamId } from './SysTeam'
import { SysTournamentMatchScheduleId } from './SysTournament'

export enum SysMatchRequestFriendlyType {
  Afventer = <any>'afventer',
  Godkend = <any>'godkend',
  Afvis = <any>'afvis'
}

export default interface SysMatchRequestDateChange {
  id: null
  kampprogram_id: SysTournamentMatchScheduleId
  kampflytning_nydato: string
  anmoder_hold_id: SysTeamId // The Team that request date change
  modtager_hold_id: SysTeamId // The Team that receives ths date change request
  kampflytning_status: boolean
  kampflytning_anmodning: SysMatchRequestFriendlyType
}
